/* Login Container */
.login-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: #000;
}

/* Form Container */
.login-form-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Logo Container */
.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.logo-text {
    font-size: 18px;
    font-weight: bold;
}

/* Welcome Message */
.welcome-message p {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

/* Login With */
.login-with p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

/* Form */
.login-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Form Fields */
.form-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.forgot-password {
    text-align: right;
    font-size: 14px;
    color: #3b00d1;
    margin-top: 5px;
}

.forgot-password a {
    color: #3b00d1;
    text-decoration: none;
}

/* No Account */
.no-account {
    text-align: center;
    margin-top: 20px;
}

.sign-up-link {
    color: #3b00d1;
    text-decoration: none;
}

/* Responsive Styling */
@media (min-width: 600px) {
    .login-form-container {
        width: 60%;
    }
}

@media (min-width: 768px) {
    .login-form-container {
        width: 40%;
    }
}
