.ButtonLogin {
    padding: 12.8px 25.6px;
    border-radius: 12px;
    background: var(--blue);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
    transition: all ease 0.5s;
    cursor: pointer;
    border: none;
    width: 100%;
    background-size: 300%;
    background-position: 0 0;
    color: #000;
    text-decoration: none;
    background-position: 90% 0%;
    background-image: linear-gradient(to right, var(--blue), var(--blue-purple), var(--blue));
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      padding: 8px 20px;
      background-position: unset;
      background-image: unset;
    }
  }
  
  .ButtonLogin:hover {
    background-position: 0 -10%;
  }
  
  .ButtonTextLogin {
    font-style: regular;
    font-size: 16px;
    line-height: calc(32.4px * 0.8);
    align-items: start;
    vertical-align: top;
    color: white;
    font-weight: 500 !important;
  }