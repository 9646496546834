@import "../../styles/commonStyle/variables.scss";

.sidebar-display {
    height: 100vh;
    // background: black;
}
.sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .logout-button {
    margin-top: auto;
    padding: 10px;
    text-align: center;
  }
  .ButtonLogin {
    padding: 12.8px 25.6px;
    border-radius: 12px;
    background: var(--blue);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
    transition: all ease 0.5s;
    cursor: pointer;
    border: none;
    width: 100%;
    background-size: 300%;
    background-position: 0 0;
    color: #000;
    text-decoration: none;
    background-position: 90% 0%;
    background-image: linear-gradient(to right, var(--blue), var(--blue-purple), var(--blue));
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      padding: 8px 20px;
      background-position: unset;
      background-image: unset;
    }
  }
  
  .ButtonLogin:hover {
    background-position: 0 -10%;
  }
  
  .ButtonTextLogin {
    font-style: regular;
    font-size: 16px;
    line-height: calc(32.4px * 0.8);
    align-items: start;
    vertical-align: top;
    color: white;
    font-weight: 500 !important;
  }

.css-dip3t8 {
    position: fixed !important;
    inset: 0;
    max-width: 16.7%;
    // height: 100vh !important;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    // padding-top: 20%;
    background-color: rgba(72, 68, 68, 0.7) !important;
    // color: rgb(225, 223, 221);
    color: black;
}

.css-1wvake5 {
    width: 100% !important;
    min-width: 100% !important;
    // border: 1px solid #3c4b64;
}

.active {
    background-color: #575252;
    color: white
}

.active :hover {
    background-color:  #575252 !important;
    color: white !important;
}

.menu-item {
    color: hsla(0,0%,100%,.6);
    background-color: #3c4b64 !important;
}

.menu-item:hover {
    color: hsla(0,0%,100%,.6);
    background-color: #3c4b64;
}
